import Swal from "sweetalert2";
import i18n from "@/libs/i18n";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  /**
   * @param form with type object
   * @returns {FormData}
   */
  prepareFormData(form) {
    const formData = new FormData();
    for (const key in form) {
      if (form[key] !== null) {
        formData.append(key, form[key]);
      }
    }
    return formData;
  },
  makeToast(variant = "success", title, body) {
    toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: i18n.t(title),
        icon:
          variant === "success"
            ? "CheckCircleIcon"
            : variant === "danger"
            ? "XCircleIcon"
            : "AlertTriangleIcon",
        variant: variant,
        text: i18n.t(body),
      },
    });
  },

  handleError(error) {
    if (error.response?.data?.message && !error.response?.data?.errors) {
      this.makeToast(
        "danger",
        i18n.t("g.send.errorTitle"),
        i18n.t(error.response.data.message)
      );
    } else if (error.response.data.errors) {
      for (const key in error.response.data.errors) {
        this.makeToast(
          "danger",
          i18n.t("g.send.errorTitle"),
          error.response.data.errors[key][0]
        );
      }
    } else {
      this.makeToast(
        "danger",
        i18n.t("g.send.errorTitle"),
        i18n.t("g.send.errorMessage")
      );
    }
  },

  filterImages(placeholderImage, name, data = null) {
    if (data) {
      for (let image of data) {
        if (image.name === name) {
          return image.path;
        }
      }
    }
    return placeholderImage;
  },

  sweetAlertConfirm(
    title,
    text,
    icon,
    confirmButtonText,
    cancelButtonText,
    callback
  ) {
    Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText,
      cancelButtonText,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        this.makeToast(
          "success",
          i18n.t("g.send.successTitle"),
          i18n.t("g.send.successMessage")
        );
      }
    });
  },

  slugify(text) {
    if (text)
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
  },

  uiAvatar(name) {
    return `https://ui-avatars.com/api/?name=${name}&background=random`;
  },

  dateTimeConvertFunction(time) {
    const timestampDate = new Date(time);
    const currentDate = new Date();
    const timeDifference = currentDate - timestampDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  },
  formatDateToDayMonth(inputDate, isAr = false) {
    const date = new Date(inputDate);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthNamesTranslated = [
      "يناير",
      "فبراير",
      "مارس",
      "ابريل",
      "مايو",
      "يونيو",
      "يوليو",
      "اغسطس",
      "سبتمبر",
      "اكتوبر",
      "نوفمبر",
      "ديسيمبر",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const arr = isAr ? monthNamesTranslated : monthNames;
    const formattedDate = `${day} ${arr[monthIndex]}`;

    return formattedDate;
  },
};
