import Vue from "vue";
import VueRouter from "vue-router";
import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn,
  getGuestToken,
} from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";

// Routers
import personal from "./routes/personal";
import classrooms from "./routes/classrooms";
import authentication from "./routes/authentication";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/classrooms/courses/courses.vue"),
      meta: {
        pageTitle: "Home",
        navActiveLink: "home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    ...authentication,
    ...personal,
    ...classrooms,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "pending-teacher",
      name: "pending-teacher",
      component: () => import("@/views/pendingTeacher/index.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: "Login" });
  }

  const userData = getUserData();
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const guestToken = getGuestToken();
    if (guestToken) return next();

    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }
  if (
    (userData?.is_approved === false ||
      (userData?.teacher && userData?.teacher?.is_approved === false)) &&
    to.name !== "pending-teacher" &&
    to.meta.resource !== "Auth"
  ) {
    next({ name: "pending-teacher" });
  }
  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
